var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[(_vm.cargando)?_c('loading'):_vm._e(),(_vm.inspeccion)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-8"},[_c('h4',[_vm._v(_vm._s(_vm.inspeccion.nombre))])]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Estado:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.estadoForm.estado_documento),expression:"estadoForm.estado_documento"}],staticClass:"form-control",attrs:{"disabled":_vm.estadoForm.estado_documento != 1 || !_vm.flagGestionado},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.estadoForm, "estado_documento", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.save]}},_vm._l((_vm.listaForm.estados_documento),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])}),0)]),_c('div',{staticClass:"form-group col-md-12"},[_c('div',{attrs:{"id":"accordion"}},_vm._l((_vm.$parent.formato_inspeccion
            .secciones),function(seccion,index_seccion){return _c('div',{key:seccion.id,staticClass:"card m-1 card-secondary"},[_c('div',{staticClass:"card-header"},[_c('a',{staticClass:"d-block w-100 collapsed",attrs:{"data-toggle":"collapse","href":`#collapse_${seccion.id}`,"aria-expanded":"false"}},[_c('h4',{staticClass:"card-title w-100"},[_vm._v(_vm._s(seccion.nombre))])])]),_c('div',{staticClass:"collapse",attrs:{"id":`collapse_${seccion.id}`,"data-parent":"#accordion"}},[_c('div',{staticClass:"card-body"},[(seccion.items_inspeccion.length > 0)?_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"75px"}},[_vm._v("Relevante")]),_c('th',[_vm._v("Item")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Observación - Valor")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v(" Cumple "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_seccion[index_seccion]),expression:"form_seccion[index_seccion]"}],staticClass:"form-control form-control-sm p-0",class:_vm.form_seccion[index_seccion] == true
                            ? 'is-valid'
                            : _vm.form_seccion[index_seccion] == false
                            ? 'is-invalid'
                            : '',attrs:{"disabled":_vm.estadoForm.estado_documento == 2},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_seccion, index_seccion, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.gestionarSeccion(index_seccion)}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Sin revisar")]),_c('option',{domProps:{"value":true}},[_vm._v("Si")]),_c('option',{domProps:{"value":false}},[_vm._v("No")])])])])]),(_vm.form[index_seccion])?_c('tbody',_vm._l((seccion.items_inspeccion),function(item,index_item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_c('i',{class:item.pivot.relevante == 1
                            ? 'fa fa-check text-success'
                            : 'fa fa-times-circle text-danger'})]),_c('td',[_vm._v(" "+_vm._s(item.nombre)+" ")]),_c('td',{staticClass:"text-center"},[((item.tipo_control == 1
                            ? 'checkbox'
                            : item.tipo_control == 2
                            ? 'text'
                            : item.tipo_control == 3
                            ? 'number'
                            : item.tipo_control == 4
                            ? 'date'
                            : 'hidden')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[index_seccion][index_item].valor),expression:"form[index_seccion][index_item].valor"}],staticClass:"form-control form-control-sm p-0",attrs:{"maxlength":item.tipo_control == 2 ? item.tamano_control : '',"disabled":_vm.estadoForm.estado_documento == 2,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[index_seccion][index_item].valor)?_vm._i(_vm.form[index_seccion][index_item].valor,null)>-1:(_vm.form[index_seccion][index_item].valor)},on:{"change":function($event){var $$a=_vm.form[index_seccion][index_item].valor,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form[index_seccion][index_item], "valor", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form[index_seccion][index_item], "valor", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form[index_seccion][index_item], "valor", $$c)}}}}):((item.tipo_control == 1
                            ? 'checkbox'
                            : item.tipo_control == 2
                            ? 'text'
                            : item.tipo_control == 3
                            ? 'number'
                            : item.tipo_control == 4
                            ? 'date'
                            : 'hidden')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[index_seccion][index_item].valor),expression:"form[index_seccion][index_item].valor"}],staticClass:"form-control form-control-sm p-0",attrs:{"maxlength":item.tipo_control == 2 ? item.tamano_control : '',"disabled":_vm.estadoForm.estado_documento == 2,"type":"radio"},domProps:{"checked":_vm._q(_vm.form[index_seccion][index_item].valor,null)},on:{"change":function($event){return _vm.$set(_vm.form[index_seccion][index_item], "valor", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[index_seccion][index_item].valor),expression:"form[index_seccion][index_item].valor"}],staticClass:"form-control form-control-sm p-0",attrs:{"maxlength":item.tipo_control == 2 ? item.tamano_control : '',"disabled":_vm.estadoForm.estado_documento == 2,"type":item.tipo_control == 1
                            ? 'checkbox'
                            : item.tipo_control == 2
                            ? 'text'
                            : item.tipo_control == 3
                            ? 'number'
                            : item.tipo_control == 4
                            ? 'date'
                            : 'hidden'},domProps:{"value":(_vm.form[index_seccion][index_item].valor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form[index_seccion][index_item], "valor", $event.target.value)}}})]),_c('td',{staticClass:"text-center"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[index_seccion][index_item].cumple),expression:"form[index_seccion][index_item].cumple"}],staticClass:"form-control form-control-sm p-0",class:_vm.form[index_seccion][index_item].cumple == true
                            ? 'is-valid'
                            : _vm.form[index_seccion][index_item].cumple == false
                            ? 'is-invalid'
                            : '',attrs:{"disabled":_vm.estadoForm.estado_documento == 2},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form[index_seccion][index_item], "cumple", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.validaGestion]}},[_c('option',{domProps:{"value":null}},[_vm._v("Sin revisar")]),_c('option',{domProps:{"value":true}},[_vm._v("Si")]),_c('option',{domProps:{"value":false}},[_vm._v("No")])])])])}),0):_vm._e()]):_c('div',{staticClass:"alert alert-warning col-12"},[_vm._m(0,true),_vm._v(" No se encontraron Items de Inspección para realizar. ")])])])])}),0)]),(_vm.array_no_cumplidos.length > 0)?_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Incumplimientos Inspección")]),_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.array_no_cumplidos),function(no_cumplido){return _c('tr',{key:no_cumplido.id},[_c('td',{staticClass:"text-center"},[(no_cumplido.relevante)?_c('i',{staticClass:"fa fa-check text-success"}):_c('i',{staticClass:"fa fa-times-circle text-danger"})]),_c('td',[_vm._v(_vm._s(no_cumplido.seccion))]),_c('td',[_vm._v(_vm._s(no_cumplido.item))]),_c('td',[_vm._v(_vm._s(no_cumplido.valor))]),_c('td',{staticClass:"text-center"},[(no_cumplido.cumple)?_c('i',{staticClass:"fa fa-check text-success"}):_c('i',{staticClass:"fa fa-times-circle text-danger"})]),_c('td',{staticClass:"text-center"},_vm._l((no_cumplido.incumplidosAnteriores),function(incumplido){return _c('div',{key:incumplido.id},[_vm._v(" "+_vm._s(incumplido.inspeccion_id)+" ")])}),0)])}),0)])]):_vm._e()]):_c('div',{staticClass:"row"},[_vm._m(2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticStyle:{"width":"75px"}},[_vm._v("Relevante")]),_c('th',[_vm._v("Sección")]),_c('th',[_vm._v("Item")]),_c('th',[_vm._v("Valor")]),_c('th',{staticStyle:{"width":"75px"}},[_vm._v("Cumple")]),_c('th',{staticStyle:{"width":"75px"}},[_vm._v("Inspecciones anteriores")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning col-12"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención!")]),_vm._v(" No se encontraron Inspecciones para realizar. ")])
}]

export { render, staticRenderFns }